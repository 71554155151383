.admin table {
    overflow: auto;
    height: 70vh;
}
.admin thead tr {
    border-bottom: 1px solid #fff;
} 
.admin thead tr th {
    width: 14.3%;
    padding-right: 0;
}
.admin tbody tr td {
    width: 14.3%;
    padding-right: 0;
}
@media (max-width:750px) {
    .admin {
        margin-top: 40px;
        height: 500px;
    }
    .admin table {
        overflow-x: auto;
    }
    .admin thead tr th {
        min-width: 150px;
    }
    .admin tbody tr td {
        min-width: 150px;
    }
}
